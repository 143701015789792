import {
  AnchorRouter,
  Button,
  ContributionLogo,
  EmergencySavingsLogo,
  FaceBookIcon,
  FynbosLogo,
  Icon,
  InstagramIcon,
  LinkedInIcon,
  Router,
  TFSALogo,
  XIcon
} from '@/Components'
import { cn } from '@/Lib'
import { Link } from '@inertiajs/react'
import * as Separator from '@radix-ui/react-separator'
import { FC, ReactNode } from 'react'
import { NavDrawer } from './NavDrawer'

type MarketingScaffoldProps = {
  children?: ReactNode
}

export const MarketingLayout: FC<MarketingScaffoldProps> = ({ children }) => {
  return (
    <div className='relative inset-0 flex min-h-dvh w-full flex-col'>
      <header className='sticky top-0 z-10 flex w-full border-b border-slate-200 bg-white/95 [@supports(backdrop-filter:blur(0))]:bg-white/80 [@supports(backdrop-filter:blur(0))]:backdrop-blur'>
        <div className='mx-auto flex w-full items-center gap-x-4 p-4 md:max-w-5xl md:py-6 lg:justify-between'>
          <div className='lg:hidden'>
            <NavDrawer>
              <NavItem href={route('contribution.index')}>
                <ContributionLogo className='p-0.5' />
                Contributions
              </NavItem>
              <NavItem href={route('emergency-savings.index')}>
                <EmergencySavingsLogo className='p-0.5' />
                Emergency savings
              </NavItem>
              <NavItem href={route('tfsa.index')}>
                <TFSALogo className='p-0.5' />
                Tax-free investments
              </NavItem>
              <div className='mt-auto' />
              <NavItem href={route('learn')}>
                <Icon>local_library</Icon>Learn
              </NavItem>
              <AnchorRouter
                target='_blank'
                href='https://wa.me/27686244900'
                className='relative flex w-full items-center gap-2 truncate rounded-xl p-3 font-normal text-medium hover:bg-nav focus-visible:outline focus-visible:outline-2 focus-visible:outline-focus'
              >
                <Icon>support</Icon>Contact support
              </AnchorRouter>
              <Separator.Root decorative className='h-px w-full bg-slate-200' />
              <NavItem href='/login'>
                <Button>Login</Button>
              </NavItem>
            </NavDrawer>
          </div>
          <Link href={'/'}>
            <FynbosLogo />
          </Link>
          <div className='hidden gap-10 lg:flex'>
            <Router href='/learn'>Learn</Router>
            <Router href='/login'>Login</Router>
          </div>
        </div>
      </header>
      {children}
      <footer className='w-full bg-black'>
        <div className='mx-auto flex w-full max-w-5xl flex-col gap-4 p-4 py-14 text-inverted'>
          <div className='flex w-full items-center justify-between'>
            <FynbosLogo />
            <div className='flex items-center gap-6'>
              <AnchorRouter href='https://www.linkedin.com/company/fynbos'>
                <LinkedInIcon className='text-white' />
              </AnchorRouter>
              <AnchorRouter href='https://twitter.com/fynbosdev'>
                <XIcon className='text-white' />
              </AnchorRouter>
              <AnchorRouter href='https://facebook.com/fynbosapp'>
                <FaceBookIcon className='text-white' />
              </AnchorRouter>
              <AnchorRouter href='https://instagram.com/fynbosdev'>
                <InstagramIcon className='text-white' />
              </AnchorRouter>
            </div>
          </div>

          <span className='text-sm text-disabled'>
            &copy; {new Date().getFullYear()} Fynbos Technologies Ltd.
          </span>
          <div className='flex flex-wrap gap-4 text-sm'>
            <AnchorRouter href='/terms-and-conditions'>
              Terms &amp; Conditions
            </AnchorRouter>
            <AnchorRouter href='/privacy-policy'>Privacy Policy</AnchorRouter>

            <AnchorRouter href='/data-security-policy'>
              Data Security Policy
            </AnchorRouter>
          </div>
        </div>
      </footer>
    </div>
  )
}

type NavItemProps = {
  children?: ReactNode
  href: string
  type?: 'doc' | 'section'
}

const NavItem: FC<NavItemProps> = ({ children, href, type }) => {
  return (
    <Router
      href={href}
      className={cn(
        type == 'section' && 'pl-8',
        'relative flex w-full items-center gap-2 truncate rounded-xl p-3 focus-visible:outline focus-visible:outline-2 focus-visible:outline-focus'
      )}
    >
      {children}
    </Router>
  )
}

NavItem.displayName = 'ListItem'
