import {
  Card,
  CardHeader,
  Chip,
  ChipColor,
  dateFormat,
  Grid,
  GridColumn,
  Icon,
  Money,
  Router
} from '@/Components'
import { cn } from '@/Lib'
import { TransactionsPageProps } from '@/types/transactions'
import { Head, usePage } from '@inertiajs/react'
import { FC, ReactNode, useMemo } from 'react'

type LayoutProps = {
  children?: ReactNode
}

// NOTE: TransactionType and TransactionStatus are also defined in App\Services\TransactionsService\Enums
export enum TransactionType {
  Deposit = 0,
  Withdrawal = 1,
  Transfer = 2,
  Buy = 3,
  Sell = 4,
  Fee = 5,
  Interest = 6,
  Dividend = 7,
  Refund = 8
}

export enum TransactionStatus {
  Failed = 0,
  Pending = 1,
  Complete = 2
}

export const TransactionsLayout: FC<LayoutProps> = ({ children }) => {
  const { transactions } = usePage<TransactionsPageProps>().props

  return (
    <Grid>
      <Head title='Transactions' />
      <GridColumn className='col-span-full gap-4 sm:col-span-4 lg:col-span-6'>
        {transactions &&
          transactions.map((transactionGroup) => (
            <Card
              key={`group-${transactionGroup[0].timestamp}`}
              className='flex flex-col gap-1'
            >
              <CardHeader>
                <span className='text-sm font-medium'>
                  {dateFormat(new Date(transactionGroup[0].timestamp))}
                </span>
              </CardHeader>
              {transactionGroup.map((transaction) => (
                <NavItem
                  key={`item-${transaction.id}`}
                  href={route('transactions.show', { id: transaction.id })}
                  className='flex items-center gap-2'
                >
                  <div className='relative flex size-10 flex-none items-center justify-center rounded-full bg-nav transition-colors group-data-[active=true]:bg-nav-active'>
                    {transaction.requiresAction && (
                      <span className='absolute right-1 top-1 size-2 rounded-full bg-rose-500' />
                    )}
                    {transaction.type == TransactionType.Deposit && (
                      <Icon>east</Icon>
                    )}
                    {transaction.type == TransactionType.Withdrawal && (
                      <Icon>west</Icon>
                    )}
                    {transaction.type == TransactionType.Transfer && (
                      <Icon>sync_alt</Icon>
                    )}
                    {transaction.type == TransactionType.Buy && (
                      <Icon>local_mall</Icon>
                    )}
                    {transaction.type == TransactionType.Sell && (
                      <Icon>sell</Icon>
                    )}
                    {transaction.type == TransactionType.Fee && (
                      <Icon>card_membership</Icon>
                    )}
                    {transaction.type == TransactionType.Interest && (
                      <Icon>trending_up</Icon>
                    )}
                    {transaction.type == TransactionType.Dividend && (
                      <Icon>add</Icon>
                    )}
                    {transaction.type == TransactionType.Refund && (
                      <Icon>undo</Icon>
                    )}
                  </div>
                  <div className='flex w-[calc(100%-3rem)] flex-col gap-1'>
                    <div className='flex items-center justify-start'>
                      <span className='truncate'>{transaction.title}</span>
                      <div className='ml-auto flex min-w-min gap-2'>
                        {transaction.status == TransactionStatus.Pending && (
                          <Chip color={ChipColor.orange}>Pending</Chip>
                        )}
                        {transaction.status == TransactionStatus.Failed && (
                          <Chip color={ChipColor.red}>Failed</Chip>
                        )}
                        <Money value={transaction.total} />
                      </div>
                    </div>
                    <div className='flex items-center text-sm text-weak'>
                      {transaction.description}
                    </div>
                  </div>
                </NavItem>
              ))}
            </Card>
          ))}
      </GridColumn>
      <GridColumn className='col-span-full gap-4 sm:sticky sm:top-[3.75rem] sm:col-span-4 lg:col-span-6'>
        {children}
      </GridColumn>
    </Grid>
  )
}

type NavItemProps = {
  children?: ReactNode
  className?: string
  href: string
}

const NavItem: FC<NavItemProps> = ({ children, href, className }) => {
  const { url } = usePage()

  const to = useMemo(() => {
    const active = new URL(url, window.location.origin)
    const current = new URL(href, window.location.origin)
    return {
      href,
      active: current.pathname === active.pathname
    }
  }, [url, href])

  return (
    <Router
      href={to.href}
      data-active={to.active}
      preserveScroll
      className={cn(
        'group relative w-full truncate rounded-xl p-2 transition-colors focus-visible:outline focus-visible:outline-2 focus-visible:outline-focus',
        to.active ? 'bg-nav-active text-strong' : 'hover:bg-nav',
        className
      )}
    >
      {children}
    </Router>
  )
}

NavItem.displayName = 'TransactionListItem'
